import React, { Component } from "react"
import Link from "gatsby-link"
import get from "lodash/get"
import Template from "../components/layout"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { DiscussionEmbed } from "disqus-react"
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Embed from "../components/embed";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      let embed = get(node, "data.target.fields.embed.en-US");
      let id = get(node, "data.target.sys.id");
      if (embed) {
        return <div dangerouslySetInnerHTML={{ __html: embed }}></div>
      } else if (id) {
        return <Embed embedId={id} />
      } else {
        return <React.Fragment />
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      let { description, title, file } = node.data.target.fields
      return <img src={file["en-US"].url} />
    },
  }
};

class BlogPostTemplate extends Component {
  render() {
    const post = get(this, "props.data.contentfulBlogPost")
    const disqusShortname = "binancelite";
    const disqusConfig = {
      identifier: post.id,
      title: post.title,
    };

    return (
      <Template>
        <Helmet title={`${post.title}`}>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@binanceliteau" />
          <meta name="twitter:creator" content="@binanceliteau" />
          <meta property="og:url" content={"https://blog.binancelite.com/" + post.slug} />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.excerpt.excerpt} />
          <meta property="og:image" content={"https:" + post.heroImage.file.url} />
        </Helmet>
        <div className="inner-blog-post">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="blog-post">
                  <Link to="/" className="entry-read-more">
                    <span />
                    Home
                  </Link>
                </div>
                <br/>
                <h1 className="section-headline"> {post.title} </h1> <br/>
                <div className="entry-media">
                  <Img
                    fluid={post.heroImage.fluid}
                    backgroundColor={"#f4f8fb"}
                  />
                </div>
                <p> {post.publishDate} </p>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: post.body.childContentfulRichText.html,
                  }}
                /> */}
                { documentToReactComponents(JSON.parse(post.body.body), options) }
                <hr/>
                <div className="blog-post">
                  {
                    post.previousPost &&
                    <Link to={post.previousPost.slug} className="entry-read-more">
                      <span />
                      Prev: {post.previousPost.title}
                    </Link>
                  }
                  {
                    post.nextPost &&
                    <Link to={post.nextPost.slug} className="entry-read-more right">
                      Next: {post.nextPost.title} <span />
                    </Link>
                  }
                </div>
                <br/>
                <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
              </div>
            </div>
          </div>
        </div>
      </Template>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query blogPostQuery($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      title
      excerpt {
        excerpt
      }
      body {
        body
      }
      heroImage {
        file {
          url
        }
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      previousPost {
        slug
        title
      }
      nextPost {
        slug
        title
      }
    }
  }
`
