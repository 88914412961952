import React from "react"
import { StaticQuery, graphql } from "gatsby"

class Embed extends React.Component {
  render() {
    return (
        <StaticQuery
            query={graphql`
              query EmbedQuery {
                allContentfulTweet {
                  edges {
                    node {
                      contentful_id
                      embed {
                        embed
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
              let tweet = data.allContentfulTweet.edges.find(tweet => tweet.node.contentful_id === this.props.embedId.slice(1));
              if (tweet) {
                return <div dangerouslySetInnerHTML={{ __html: tweet.node.embed.embed }}></div>
              } else {
                return <React.Fragment />
              }
            }}
        />
    )
  }
}

export default Embed